<template>
  <div>
    <main>
      <div class="text-center border-bottom bg-primary text-bg-primary maxvp d-flex justify-content-center align-items-center">
        <div class="mw-100 maxWitvp text-center">
          <h1 class="display-1 fw-bold">VatID.Cloud</h1>
          <div class="col-lg-12 mx-auto mt-2">
<!--            <p class="lead mb-4 display-5">Getting the data it's easy!</p>-->
<!--            <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">-->
<!--              <a type="button" class="btn btn-light btn-lg px-4 me-sm-3 mb-5" data-bs-toggle="modal" href="#exampleModalToggle">Go to Search</a>-->
<!--            </div>-->
            <div class="search-container">
              <input type="text" class="search-box" placeholder="Search Company on VatID.Cloud" v-model="search_words">
            </div>
            <br />
            <button class="btn btn-lg btn-light" data-bs-toggle="modal" href="#exampleModalToggle" @click.pr.prevent="send">Search</button>
          </div>
        </div>
      </div>

      <!-- -->
      <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-fullscreen-sm-down modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Search on VatID.Cloud Database</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <search-vat :key-search="searchValue"></search-vat>
            </div>
          </div>
        </div>
      </div>

      <div class="container col-xxl-8 px-4 py-5">
        <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div class="col-10 col-sm-8 col-lg-6">
            <img src="lady_browser.svg" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
          </div>
          <div class="col-lg-6">
            <h1 class="display-5 fw-bold lh-1 mb-3">Are you looking for information about European companies?</h1>
            <p class="lead">VatID.Cloud allows searching by name in a single portal for the minimum information of European companies available in its database</p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center">
              <a type="button" class="btn btn-primary btn-lg px-4 me-md-2" data-bs-toggle="modal" href="#exampleModalToggle">Go to Search</a>
            </div>
          </div>
        </div>
      </div>

      <div class="container col-xxl-8 px-4 py-5">
        <div class="row  align-items-center g-5 py-5">
          <div class="col-10 col-sm-8 col-lg-6">
            <img src="man_browser.svg" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
          </div>
          <div class="col-lg-6">
            <h1 class="display-5 fw-bold lh-1 mb-3">One goal just Simplify!</h1>
            <p class="lead">We created this platform to allow everyone in a quick and easy way to retrieve this information from across the euro area</p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center">
              <a type="button" class="btn btn-primary btn-lg px-4 me-md-2" data-bs-toggle="modal" href="#exampleModalToggle">Go to Search</a>
            </div>
          </div>
        </div>
      </div>

      <div class=" container col-xxl-8 px-4 py-5">
        <div class="row  align-items-center g-5 py-5">
            <div class="col-12">
              <h4 class="display-5 fw-bold lh-1 mb-3">What do you get with VatID.Cloud? </h4>
            </div>
        </div>
        <div class="row  align-items-center g-5 py-5">
          <div class="col-12 col-sm-12 col-lg-4">
            <img src="man_invoice.svg" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
            <div>
              <p class="lead mt-5">The minimum details you need to issue your invoices</p>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-lg-4">
            <img src="man_ceo.svg" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
            <div>
              <p class="lead mt-5">More Time to Dedicate to Your Business, Thanks to Less Time for Information Retrieval</p>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-lg-4">
            <img src="man_cfo.svg" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
            <div>
              <p class="lead mt-5">Security that the data has been validated</p>
            </div>
          </div>
        </div>
      </div>


      <div class="container my-5">
        <div class="row flex-lg-row-reverse p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg justify-content-center">
          <div class="col-lg-6 p-3 p-lg-5 pt-lg-3">
            <h2 class="display-5 fw-bold lh-1">all this for one simple price</h2>
            <br />
            <h6 class="display-6">Only 0.19 €/day </h6>
            <p class="lead"></p>
          </div>
          <div class="col-lg-5 offset-lg-1 p-0 overflow-hidden shadow-lg">
            <div class="card text-center text-bg-primary">
              <div class="card-body">
                <h5 class="card-title display-1">5,90 €/month</h5>
<!--                <p class="card-text lead">-->
<!--                  0.19 € for day-->
<!--                </p>-->
                <p class="card-text"></p>
              </div>
            </div>
          </div>
          <br />
          <p>price valid for registrations until <b>31/12/2023</b>, then change to € 12.99/month</p>
        </div>
      </div>


      <div class=" container col-xxl-8 px-4 py-5">
        <div class="row align-items-center g-5 py-5">
          <div class="col-12">
            <h4 class="display-5 fw-bold lh-1 mb-3">What are you waiting for again? </h4>
            <div class="d-grid gap-2 d-md-flex justify-content-md-center">
              <a type="button" class="btn btn-primary btn-lg px-4 me-md-2" data-bs-toggle="modal" href="#exampleModalToggle">Go to Search</a>
            </div>
          </div>
        </div>
      </div>


      <div class="row bgFooter text-light">
        <div class="col-lg-6">
          <div class="text-start m-4">
            <h4 class="display-4 fw-bold text-white">VatID</h4>
            <h6>Project by</h6>
            <h6 class="">Pellizzaro Development Projects</h6>
            <h6 class="">di Pellizzaro Alessio - IT04380170243 - San Martino Buon Albergo, Italy</h6>
            <h6>© 2020 - {{ new Date().getFullYear() }} - @VatID.Cloud - <a class="text-decoration-none text-light" href="mailto:info@vatid.cloud?subject=Information&body=Hello!" >info@vatid.cloud</a> - Europe</h6>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="text-start m-4 text-light">
            <ul class="list-unstyled list-group list-group-flush">
              <li class="">
                <router-link tag="a" to="/" class="fs-4 text-decoration-none text-light">Home</router-link>
              </li>
              <li class="">
                <router-link tag="a" to="/Login" class="fs-4 text-decoration-none text-light" >Login</router-link>
              </li>
              <li class="">
                <router-link tag="a" to="/Register" class="fs-4 text-decoration-none text-light" v-if="!betaState" >Register</router-link>
              </li>
              <li class="">
                <router-link tag="a" to="/Charge" class="fs-4 text-decoration-none text-light" v-if="!betaState" >Charge</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="text-start m-4 text-light">
            <ul class="list-unstyled list-group list-group-flush">
              <li class="">
                <router-link tag="a" to="/search" class="fs-4 text-decoration-none text-light" >Search by Name</router-link>
              </li>
              <li class="">
                <router-link tag="a" to="/searchByVat" class="fs-4 text-decoration-none text-light" >Search by Vat</router-link>
              </li>
              <li class="">
                <router-link tag="a" to="/user" class="fs-4 text-decoration-none text-light" >User</router-link>
              </li>
              <li class="">
                <router-link tag="a" to="/credit" class="fs-4 text-decoration-none text-light" >Credit User</router-link>
              </li>
              <li class="">
                <router-link tag="a" to="/history" class="fs-4 text-decoration-none text-light" >History User</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="text-start m-4 text-light">
            <ul class="list-unstyled list-group list-group-flush">
              <li class="">
                <router-link tag="a" to="/ticket" class="fs-4 text-decoration-none text-light" >Support</router-link>
              </li>
              <li class="">
                <a href="https://www.iubenda.com/privacy-policy/14075729" class="fs-4 text-decoration-none text-light" title="Privacy Policy ">Privacy Policy</a>
              </li>
              <li class="">
                <a href="https://www.iubenda.com/privacy-policy/14075729/cookie-policy" class="fs-4 text-decoration-none text-light">Cockies</a>
              </li>
              <li class="">
                <a href="https://www.iubenda.com/terms-and-conditions/14075729" class="fs-4 text-decoration-none text-light">Terms & Condition</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  </div>

</template>

<script>
import * as GetISO from "@/helpers/convertToNationISO";
export default {
  name: 'NewHome',
  data () {
    return {
      text_send : "",
      search_words : ""
    }
  },
  beforeMount(){

  },
  computed: {
    betaState: function () {
      return process.env.VUE_APP_BETA_STATE;
    },
    searchValue: function () {
      if(this.text_send !== ""){
        return this.text_send
      }else {
        return null
      }
    }
  },
  methods : {
    send() {
      if(this.search_words !== ""){
         this.text_send = this.search_words;
         this.search_words = "";
      }
    }
  }
}



</script>
<style>

.maxvp {
  height: 80vh !important;
}
.maxWitvp {
  width: 100vh !important;
}

.bgFooter {
  background-color: #4D4D4D;
}
.search-container {
  display: flex;
  justify-content: center;
}

/* Stile per la barra di ricerca */
.search-box {
  width: 75vh;
  height: 5vh;
  padding: 10px;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 25px;
}

/* Stile per il pulsante di ricerca */
.search-button {
  background-color: #4285f4;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
}

/* Stile al passaggio del mouse sul pulsante di ricerca */
.search-button:hover {
  background-color: #357ae8;
}
</style>

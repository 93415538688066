<template>
  <div>
    <div class="container">
      <template v-if="!presOrder">
        <div class="row text-center mt-5">
          <h1 class="heading-title">Active Subscription</h1>
        </div>
      </template>
      <div>
        <div class="row mt-5">
          <div class="col-md-4 col-sm-6 mb-4 mx-auto ">
            <div class="pricingTable">
              <div class="pricingTable-header">
                <h3 class="heading">VatID.Cloud Fee</h3>
                <div class="price-value"> 5.90
                  <span class="currency">€</span>
                  <span class="month">/MM</span>
                </div>
              </div>
              <span><b> € 0.19 for Day!</b></span>
              <br />
              <ul class="pricing-content">
                <h6>First Month it's Free</h6>
                <h6>This price is discounted for those who sign up by December 31, 2023.</h6>
                <h6><b>then for those who do not register in time it goes up to €12.99</b></h6>

                <template v-if="hasFee">
                  <li><button class="btn btn-outline-danger btn-lg" data-bs-toggle="modal" href="#DeactivateFee"> Deactivate Fee</button></li>
                </template>
                <template v-if="!hasFee">
                  <template v-if="validateCard">
                    <li><button class="btn btn-outline-primary btn-lg" @click.prevent="order">
                      <template v-if="send">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></template>
                      Subscrive!
                    </button></li>
                  </template>
                  <template v-if="!validateCard">
                    <li><button class="btn btn-success btn-lg" data-bs-toggle="modal" href="#addCardtoggel"> Subscrive!</button></li>
                  </template>
                </template>
              </ul>
              <div class="row">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="DeactivateFee" aria-hidden="true" aria-labelledby="DeactivateFeeLabel" tabindex="-1">
      <div class="modal-dialog modal-fullscreen-sm-down modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="DeactivateFeeLabel">Confirm Choise?</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <h4>Confirm Deactivate Your VatID.Cloud Fee?</h4>
            <button type="button" class="btn btn-primary btn-lg" data-bs-dismiss="modal" aria-label="Close">Remain Active</button>
            <button type="button" class="btn btn-danger btn-lg" @click.prevent="Deactivate">Deactivate My Fee</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="addCardtoggel" aria-hidden="true" aria-labelledby="addCardtoggelLabel" tabindex="-1">
      <div class="modal-dialog modal-fullscreen-sm-down modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="addCardtoggelLabel">Add Your Card</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <add-card :add-sub="true"></add-card>
          </div>
        </div>
      </div>
    </div>
    <div class="m-5">
      <p>for info status you order or problem on sub open ticket o send mail to info@vatid.cloud <br /> VatID.Cloud - Pellizzaro Development Projects - Europe</p>
    </div>
  </div>
</template>
<script>


import { mapGetters, mapActions } from 'vuex'
import * as ApiService from '@/helpers/api.service'
import {del} from "@/helpers/api.service";
export default {
  name: "Fee",
  components: {},
  data() {
    return {

      card : {},
      verbose : '',
      creditForm : '',
      year : 'year',
      month : 'month',
      presOrder : false,
      send : false,
      serverDate : '',
      planID : 0
    }
  },
  beforeMount() {
    this.getUserInfo();
    this.getUserCard();
    this.getUserSub();
    },
  methods : {
    changeStatus() {
      this.presOrder = !this.presOrder;
    },
   async order () {
      this.send = true;
      let url = `/auth/user/info/sub`;
      await ApiService.post(url, { planID : this.planID }).then((res) => {
        this.$notify({
          title: 'Success Subscription',
          type : 'success',
          duration: 10000,
          speed: 1000,
          text: `Hi!\n <br> thanks you for Subscription on VatID.Cloud`
        });
        this.$router.push('/');
      }).catch((err) =>{
        this.$notify({
          title: 'Error Subscription',
          type : 'error',
          duration: 10000,
          speed: 1000,
          text: `Error on Add Your Subscription <br /> Please try again later`
        });
        this.send = false;
        console.log(err);
      })
    },
    async Deactivate() {
      this.send = true;
      let url = `/auth/user/info/sub/del`;
      await ApiService.del(url).then((res) => {
        this.$notify({
          title: 'Success Canceled Subscription',
          type : 'success',
          duration: 10000,
          speed: 1000,
          text: `Hi!\n <br> thanks on VatID.Cloud`
        });
        this.$router.push('/');
      }).catch((err) => {
        this.$notify({
          title: 'Error Subscription',
          type: 'error',
          duration: 10000,
          speed: 1000,
          text: `Error on Canceled Your Subscription <br /> Please try again later`
        });
        this.send = false;
        console.log(err)
      });
    },
    ...mapActions({
      getUserInfo: 'getUserInfo',
      getUserCard : 'getUserCard',
      getUserSub : 'getUserSub',
    }),

  },
  computed : {
    validateCard: function() {
      return this.$store.state.card.length > 0;
    },
    hasFee: function() {
      return this.$store.state.sub.length > 0;
    },
  }
}
</script>

<style scoped>
.home
{
  padding: 50px 0;
}
.heading-title
{
  margin-bottom: 50px;
}

.pricingTable{
  border: 2px solid #e3e3e3;
  text-align: center;
  position: relative;
  padding-bottom: 40px;
  transform: translateZ(0px);
}

.pricingTable:before,
.pricingTable:after{
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
  z-index: -1;
  transition: all 0.5s ease 0s;
}

.pricingTable:before{
  border-right: 2px solid #0d6efd;
  border-left: 2px solid #0d6efd;
  transform: scaleY(0);
  transform-origin: 100% 0 0;
}

.pricingTable:after{
  border-bottom: 2px solid #0d6efd;
  border-top: 2px solid #0d6efd;
  transform: scaleX(0);
  transform-origin: 0 100% 0;
}

.pricingTable:hover:before{
  transform: scaleY(1);
}

.pricingTable:hover:after{
  transform: scaleX(1);
}

.pricingTable .pricingTable-header{
  background: #0d6efd;
  color: #fff;
  margin: -2px -2px 35px;
  padding: 40px 0;
}

.pricingTable .heading{
  font-size: 30px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.pricingTable .subtitle{
  font-size: 14px;
  display: block;
}

.pricingTable .price-value{
  font-size: 72px;
  font-weight: 600;
  margin-top: 10px;
  position: relative;
  display: inline-block;
}

.pricingTable .currency{
  font-size: 45px;
  font-weight: normal;
  position: absolute;
  top: 2px;
  left: -30px;
}

.pricingTable .month{
  font-size: 20px;
  position: absolute;
  bottom: 17px;
  right: -40px;
}

.pricingTable .pricing-content{
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}

.pricingTable .pricing-content li{
  font-size: 16px;
  color: #7a7e82;
  line-height: 40px;
}

.pricingTable .read{
  display: inline-block;
  border: 2px solid #7a7e82;
  border-right: none;
  font-size: 14px;
  font-weight: 700;
  color: #7a7e82;
  padding: 9px 30px;
  position: relative;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

.pricingTable .read:hover{
  border-color: #0d6efd;
  color: #0d6efd;
}

.pricingTable .read i{
  font-size: 19px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  right: 15px;
  transition: all 0.3s ease 0s;
}

.pricingTable .read:hover i{
  right: 5px;
}

.pricingTable .read:before,
.pricingTable .read:after{
  content: "";
  display: block;
  height: 30px;
  border-left: 2px solid #7a7e82;
  position: absolute;
  right: -11px;
  transition: all 0.3s ease 0s;
}

.pricingTable .read:before{
  bottom: -6px;
  transform: rotate(45deg);
}

.pricingTable .read:after{
  top: -6px;
  transform: rotate(-45deg);
}

.pricingTable .read:hover:before,
.pricingTable .read:hover:after{
  border-left-color: #0d6efd;
}

@media screen and (max-width: 990px){
  .pricingTable{ margin-bottom: 25px; }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
//import User from "./user.module"
import Company from "./company.module"
import Credit from "./credit.module"
import Admin from "./admin.module"
import * as ApiSerivice from "@/helpers/api.service";
import router from "@/router";
import createPersistedState from "vuex-persistedstate";
//import * as ApiSerivice from "@/helpers/api.service";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    idToken : '',
    idUser : '',
    ticket : [],
    user : null,
    card : [],
    sub : [],
    credit : null,
    error : false,
    isAdmin : false,
    invoiceDate : {},
    haveInvoiceDate : false
  },
  mutations: {
    authUser( state , userData){
      state.idUser = userData.idUser;
      state.idToken = userData.token;
    },
    storeUser (state, user){
      state.user = user.user;
    },
    storeTicket (state, ticket){
      state.ticket = ticket;
    },
    addToken( state , token){
      state.idToken = token;
    },
    addErro( state , err){
      state.error = err;
    },
    AddAdmin (state, IsAd) {
      state.isAdmin = IsAd;
    },
    addInvoiceDate (state, invoiceDate) {
      state.invoiceDate = invoiceDate;
    },
    addhaveInvoiceDate (state, haveInvoiceDate) {
      state.haveInvoiceDate = haveInvoiceDate;
    },
    addInfoUser (state, data) {
      state.user = data;
    },
    addUserCard (state, card) {
      state.card = card;
    },
    addUserSub(state, sub) {
      state.sub = sub;
    }

  },
  actions: {
    async register({commit, dispatch}, formData){
     await ApiSerivice.post('/auth/register', { email: formData.email , name: formData.name, surname: formData.surname, password: formData.password}).then((res) => {
        return res.data;
      }).catch((err) => {
        if(err){
        }
      })
    },
    async login({commit, dispatch}, formData){
      await ApiSerivice.post('/auth/login', { email: formData.email , password: formData.password}).then((res) => {
        window.localStorage.setItem(process.env.VUE_APP_ID_TOKEN, res.data.token);
        commit('storeUser', { user:res.data.user });
        commit('authUser', {idUser : res.data.user.id ,token :res.data.token });
        commit('AddAdmin', { IsAd : Boolean(res.data.admin )});
        commit('addInvoiceDate', { ...res.data.billingsInfo });
        const ID_TOKEN_KEY = 'token';
        let getToken = window.localStorage.getItem(ID_TOKEN_KEY);
        if(!this.state.idToken) {
          if(!getToken){
            router.push('/login/');
            return null;
          }
          else {
            commit('addToken', { token:getToken });
          }
        }

        ApiSerivice.init();
        if(formData.returnPage) {
          router.push(formData.returnPage);
        }
        else  {
          router.push('/');
        }

       // console.log(res.data.token);
      }).catch((err) => {
        if(err){
          commit('addErro', { err: true });
          //return err;
        }
      });

    },
    reload({commit, dispatch}){

    },
    logout({commit, dispatch}){
      window.localStorage.removeItem(process.env.VUE_APP_ID_TOKEN);
      commit('addToken', null);
      localStorage.removeItem("vuex");
      router.push('/login/');
    },
    storeUserAc({commit, state}){
      const ID_TOKEN_KEY = 'token';
      var getToken = window.localStorage.getItem(ID_TOKEN_KEY);
      if(!this.state.idToken) {
        if(!getToken){
          router.push('/login/');
          return null;
        }
        else {
          commit('addToken', { token:getToken });
        }
      }
      return getToken;
    },
    getCreditUser({commit, dispatch}){
      ApiSerivice.get('/credit/').then((res) => {
        //console.log(res.data);
        res.data.data.disponible_credit = res.data.Disponible_Credit;
        res.data.data.history = res.data.history;
        commit('storeUser', { user:res.data.data });
      }).catch((err) => {
        router.push('/login/');
      })
    },
    getTicketUser({commit, dispatch}){
      ApiSerivice.get('/ticket/').then((res) => {
        commit('storeTicket', { ticket : res.data.Ticket});
      })
    },
    getUserIsAdmin({commit, dispatch}){
      ApiSerivice.get('/admin/').then(
          (res) => {
            commit('AddAdmin', { IsAd : Boolean(res.data.IsAdmin)});
          }
      )
    },
    getInvoiceDate({ commit, dispatch}) {
      ApiSerivice.get('/auth/user/info/billing').then(
          (res) => {
            commit('addInvoiceDate', { invoiceDate : res.data.billingsInfo });
          }
      )
    },
    async getUserSub({ commit, dispatch}) {
      await ApiSerivice.get('/auth/user/info/sub').then(
          (res) => {
            commit('addUserSub', res.data.sub);
          }
      )
    },
    getUserInfo({ commit, dispatch}) {
      ApiSerivice.get('/auth/user/info').then(
          (res) => {
            commit('addInfoUser', {  ...res.data.user });
          }
      )
    },
    async getUserCard({ commit, dispatch}) {
      await ApiSerivice.get('/auth/user/info/card').then(
          (res) => {
            commit('addUserCard', {  ...res.data.card });
          }
      )
    },
    getHaveInvoiceDate({ commit, dispatch}) {
      ApiSerivice.get('/auth/user/invoice/info/test').then(
          (res) => {
            commit('addhaveInvoiceDate', { haveInvoiceDate : Boolean(res.data.Test)});
          }
      )
    },
    postTicket({commit, dispatch}, formData){
      ApiSerivice.post('ticket/add/', { title: formData.title , text: formData.text}).then((res) => {
        router.push(`/ticket/${res.data.ticket.id}`);
      })
    },
    postBillInfo({commit, dispatch}, formData){
      ApiSerivice.post('/auth/user/billdata/', formData).then((res) => {
        router.push(`/`);
      })
    },
    postInvoiceDate({ commit, dispatch}, formData) {
      ApiSerivice.post('/auth/user/info/billing', formData).then(
          () => {
            router.push(`/`);
          }
      )
    },
    postCard({ commit, dispatch}, card) {
      ApiSerivice.post('/auth/user/info/card', card).then(
          () => {
            router.push(`/`);
          }
      )
    },
    postUpdInvoiceDate({ commit, dispatch}, formData) {
      ApiSerivice.post('/auth/user/info/billing', formData).then(
          () => {
            router.push(`/`);
          }
      )
    },
  },
  modules: {
    Company,
    Credit,
    Admin,
  },
  plugins: [createPersistedState()],
  getters : {
    user(state){
      return state.user;
    },
    card(state){
      return state.card;
    },
    token(state){
      let token = state.idToken;
      if(!token){
        return window.localStorage.getItem('token');
      }
      else {
        return token
      }
    },
    ticket(state){
      return state.ticket;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
    isAuth(state) {
      if (!state.idToken){
        return null;
      }
      else {
        return true
      }
      //return state.idToken !== null;
    },
    haveInvoiceDate(state) {
      return !!state.user.billing;
    },
    InvoiceDate(state) {
      return state.invoiceDate;
    },
    isErr(state) {
      if (!state.error){
        return false;
      }
      else {
        return true
      }
      //return state.idToken !== null;
    }
  }
})

<template>
  <main>
    <template v-if="!louder" class="container p-2">
      <div class="align-items-center pt-5">
        <strong>Loading...</strong>
        <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
      </div>
    </template>
    <template v-if="louder" class="container p-2">
      <div class="container p-2">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body shadow rounded">
                <h5 class="card-title display-3 "> Hi {{ this.$store.state.user.name }} {{ this.$store.state.user.surname }} </h5>
                <h6 class="card-subtitle mb-2 text-muted">{{ this.$store.state.user.email }}</h6>
                <p class="card-text">Welcome to your dedicated area</p>
                <router-link  to="/history" tag="a" class="card-link btn btn-outline-primary btn-lg"><i class="fad fa-history"></i> View Your History</router-link>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row pt-3">
          <div class="p-2 col-md-3">
            <div class="card border-primary p-0">
              <div class="card-header text-bg-primary ">
                <h4>My Informations</h4>
                <button class="card-link btn  btn-outline-light btn-xs" data-bs-toggle="modal" href="#addUserInfotoggel">Update My info</button>
              </div>
              <div class="modal fade" id="addUserInfotoggel" aria-hidden="true" aria-labelledby="addUserInfotoggelLabel" tabindex="-1">
                <div class="modal-dialog modal-fullscreen-sm-down modal-xl">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="addUserInfotoggelLabel">Add Your Card</h1>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <add-user-info></add-user-info>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="list-group list-group-flush">
                <template v-for="[index, el] in keys.billing.entries()">
                  <li class="list-group-item" v-if="userDate.billing[el] != null" >{{ el }}: {{ userDate.billing[el] }}</li>
                </template>
              </ul>
            </div>
          </div>
          <div class="p-2 col-md-3">
            <div class="card border-primary p-0">
              <div class="card-header text-bg-primary ">
                <h4>My Cards</h4>
                <button class="card-link btn  btn-outline-light btn-xs" data-bs-toggle="modal" href="#addCardtoggel">Update My Card</button>
              </div>
              <ul class="list-group list-group-flush">
                <template v-for="el in card">
                  <li class="list-group-item" >**** **** **** {{ el.card.last4 }} / {{el.card.brand}} </li>
                </template>
                <div class="modal fade" id="addCardtoggel" aria-hidden="true" aria-labelledby="addCardtoggelLabel" tabindex="-1">
                  <div class="modal-dialog modal-fullscreen-sm-down modal-xl">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-5" id="addCardtoggelLabel">Add Your Card</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        <add-card :add-sub="false"></add-card>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
          <div class="p-2 col-md-6">
            <div class="card border-primary p-0">
              <div class="card-header text-bg-primary ">
                <h4>My Fee</h4>
                <template v-if="sub.length > 0">
                  <router-link to="/fee" custom v-slot="{ navigate }">
                    <button @click="navigate" @keypress.enter="navigate" class=" btn  btn-outline-light btn-xs" type="submit">Manage My Fee</button>
                  </router-link>
                </template>
                <template v-if="sub.length < 1">
                  <router-link to="/fee" custom v-slot="{ navigate }">
                    <button @click="navigate" @keypress.enter="navigate" class=" btn  btn-success btn-xs" type="submit">Join to Fee</button>
                  </router-link>
                </template>
              </div>
              <template v-if="sub.length > 0">
                <div class="card-body">
                  <h5>VatID.Cloud Base Fee</h5>
                  <br />
                  <p> Status : {{ statusFee }} </p>
                  <p> Aumont : € {{ amount }} / month </p>
                  <p> Current Start Period: {{ currente_Start_period }} </p>
                  <p> Current End Period: {{ currente_end_period }} </p>
                </div>
              </template>
              <template v-if="sub.length < 1">
                <div class="card-body">
                  <h5>Not Activate VatID.Cloud Base Fee</h5>
                </div>
              </template>
            </div>
          </div>
        </div>
        <br />
        <div class="row pt-3">
          <div class="col-sm-12">
            <div class="card border-primary p-0">
              <div class="card-header text-bg-primary ">
                <h4>My ApiKey</h4>
              </div>
              <div class="card-body">
                <p>The Api VatID.Cloud it's a simple endpoint for find Company by params:  <code>name</code>, <code>address</code>,  <code>countryCode</code>, <code>ID</code></p>
                <p>Try Now Send requests to Endpoint <code>https://api.vatid.cloud/api/v1/company</code> </p>
                <p>Auth Required, use this bearer token</p>
                <p>at Moment Your ApiKey is: {{ apikeyStatus }} </p>
                <button class="btn btn-primary btn-lg" @click.p.prevent="copyToken">Copy Token</button>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body shadow rounded">
                <h5 class="card-title"> You need to update your data ?  </h5>
                <h6 class="card-subtitle mb-2 text-muted">select option</h6>
                <p class="card-text">for go to update page</p>
                <router-link  to="/user/update/name" tag="a" class="card-link btn btn-outline-warning btn-lg"><i class="fad fa-signature"></i> Update Your Name</router-link>
                <router-link  to="/user/update/surname" tag="a" class="card-link btn btn-outline-warning btn-lg"><i class="fad fa-file-signature"></i> Update Your Surname</router-link>
                <router-link  to="/user/update/email" tag="a" class="card-link btn btn-outline-warning btn-lg"><i class="fad fa-envelope"></i> Update Your Email</router-link>
                <router-link  to="/user/update/psw" tag="a" class="card-link btn btn-outline-warning btn-lg"><i class="fad fa-key"></i> Update Your Password</router-link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </template>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: "UserHome",
  data() {
    return {
      louder : true,
      send : false,
      userDate : {},
      card : [],
      sub : [],
      keys : {
        billing : [],
        api : [],
      }
    }
  },
  computed : {
    currente_Start_period : function () {
      if(this.sub.length > 0) {
          let date = new Date(this.sub[0].current_period_start * 1000);
          //date.setUTCMilliseconds(this.sub[0].current_period_start);
          return date.toDateString();
      }
      else {
        return null;
      }
    },
    currente_end_period: function () {
      if(this.sub.length > 0) {
        let dateEnd = new Date(this.sub[0].current_period_end * 1000);
       // dateEnd.setUTCMilliseconds(this.sub[0].current_period_end);
        return dateEnd.toDateString();
      }
      else {
        return null;
      }
    },
    amount : function () {
      if(this.sub.length > 0) {
        return (this.sub[0].plan.amount/100).toFixed(2);
      }
      else {
        return null;
      }
    },
    statusFee : function () {
      if(this.sub.length > 0) {
        return this.sub[0].status;
      }
      else {
        return null;
      }
    },
    apikeyStatus : function () {
      if(this.$store.state.user.apiKey) {
        if(this.$store.state.user.apiKey.active) {
          return "ACTIVE"
        }
        else {
          return "INACTIVE - add subscription for activate"
        }
      }else  {
        return null
      }
    }
  },
  async beforeMount() {
   await this.Load();
  },
  methods : {
    ...mapActions({
      getUserInfo: 'getUserInfo',
      getUserCard : 'getUserCard',
      getUserSub : 'getUserSub',
    }),
    async Load () {
      await this.getUserCard();
      await this.getUserSub();
      this.userDate = this.$store.state.user;
      this.card = this.$store.state.card;
      this.sub = this.$store.state.sub;
      delete this.userDate.billing.stripeId;
      delete this.userDate.billing.blocked;
      delete this.userDate.billing.addebitFee;
      delete this.userDate.billing.userId;
      delete this.userDate.billing.id;

      this.keys.billing =Object.keys(this.userDate.billing);

    },
    copyToken() {
      navigator.clipboard.writeText(this.$store.state.user.apiKey.token);
      this.$notify({
        title: 'Success Copy',
        type : 'success',
        duration: 10000,
        speed: 1000,
        text: `Copied ApiKey Token!`
      });
    }

  }
}
</script>

<style scoped>

</style>
